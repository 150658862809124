<template>
  <div class="full-area vertically-centered bg-image traditional-question traditional-question-outer-wrapper">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
      :no-title="true"
    ></QuestionHeader>

    <content class="content-wrapper delay-entry short-delay">
      <div class="location-selector-wrapper">
        <div v-if="data.fields.type === 'location' && $store.state.survey.location.country && data.fields.locationSettings.includes('State')">
          {{$store.state.survey.location.country.name}} <span v-if="!this.data.fields.defaultPreviousSelection">— </span> 
          <router-link :to="`${qid - 1}`" v-if="!this.data.fields.defaultPreviousSelection">Cambiar país</router-link>
        </div>

        <div v-if="data.fields.type === 'location' && $store.state.survey.location.state && data.fields.locationSettings.includes('City')">
          {{$store.state.survey.location.state.name}} — <router-link :to="`${qid - 1}`">Cambiar departamento</router-link>
        </div>
      </div>

      <!--<div v-if="data.fields.type === 'consent'">
        <vue-markdown class="html-content">{{data.fields.content}}</vue-markdown>
      </div>
      <div v-if="data.fields.type !== 'consent'">
        <vue-markdown v-if="isMarkdown" class="html-content">{{data.fields.content}}</vue-markdown>
        <h2 v-else>{{data.fields.content}}</h2>
      </div>-->
      <vue-markdown class="html-content">{{data.fields.content}}</vue-markdown>
      
      <div class="grid-wrapper" v-if="data.fields.type !== 'consent'">
        <div class="field-wrapper">
          <el-input placeholder="Email" @keyup.enter.native="next()" v-model="fieldValue" v-if="data.fields.type === 'email'" :key="data.fields.type" :required="data.fields.mandatory" type="email" required autocomplete="email" name="email" id="frmEmailA"></el-input>

          <el-input
            :placeholder="data.fields.placeholder || defaultPlaceholderText"
            @keyup.enter.native="next()"
            v-if="data.fields.type === 'text'"
            :key="data.fields.type"
            :required="data.fields.mandatory"
            v-model="fieldValue"
            :autocomplete="data.fields.autocomplete || 'name'"
            :name="data.fields.autocomplete === 'new-password' ? `randomName_${Math.random()}` : data.fields.autocomplete"
          />

          <el-input
            :placeholder="data.fields.placeholder || defaultPlaceholderText"
            @keyup.enter.native="next()"
            v-if="(data.fields.type === 'userId' || data.fields.type === 'nickname') && !data.fields.inputmask"
            :key="data.fields.type"
            :required="data.fields.mandatory"
            v-model="fieldValue"
            :autocomplete="data.fields.autocomplete || 'name'"
            name="name"
          />

          <input
            class="textfield-inputmask"
            :placeholder="data.fields.placeholder || defaultPlaceholderText"
            @keyup.enter.native="next()"
            v-if="(data.fields.type === 'userId' || data.fields.type === 'nickname') && data.fields.inputmask"
            :key="data.fields.type"
            :required="data.fields.mandatory"
            type="text"
            v-model="fieldValue"
            v-mask="`${data.fields.inputmask}`"
            v-on:keyup="maskCheck"
            name="name"
          />

          <el-input
            :placeholder="data.fields.placeholder || defaultPlaceholderText"
            @keyup.enter.native="next()"
            v-model="fieldValue"
            v-if="data.fields.type === 'number' && !data.fields.inputmask"
            type="number"
            :autocomplete="data.fields.autocomplete || 'phone'"
            :key="data.fields.type"
            :required="data.fields.mandatory"
            :min="data.fields.greaterThanZero ? 0 : -999999999999"
          ></el-input>

          <input
            class="textfield-inputmask"
            type="text"
            :placeholder="data.fields.autocomplete"
            v-model="fieldValue"
            v-if="data.fields.type === 'number' && data.fields.inputmask"
            v-mask="`${data.fields.inputmask}`"
            v-on:keyup="maskCheck"
          />

          <input
            class="textfield-inputmask"
            type="number"
            :placeholder="'currency'"
            v-model="fieldValue"
            v-if="data.fields.type === 'currency'"
          />

          <el-input
            :placeholder="data.fields.placeholder || defaultPlaceholderText"
            v-model="fieldValue"
            v-if="data.fields.type === 'textarea'"
            :maxlength="data.fields.charLimit || 900"
            type="textarea"
            rows="6"
            :key="data.fields.type"
            :required="data.fields.mandatory"
          ></el-input>

          <div class="year-type-wrapper" v-if="data.fields.type === 'year'">
            <el-select
              v-model="fieldValue"
              placeholder="Año"
              class="date-year"
              key="date-year"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 100"
                :key="option"
                :label="2020 - option"
                :value="2020 - option"
              ></el-option>
            </el-select>
          </div>

          <div class="year-type-wrapper" v-if="data.fields.type === 'age14'">
            <el-select
              v-model="fieldValue"
              :placeholder="data.fields.placeholder || '--'"
              class="date-year"
              key="date-year"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 67"
                :key="option"
                :label="13 + option"
                :value="13 + option"
              ></el-option>
            </el-select>
          </div>

          <div class="year-type-wrapper" v-if="data.fields.type === 'age18'">
            <el-select
              v-model="fieldValue"
              :placeholder="data.fields.placeholder || '--'"
              class="date-year"
              key="date-year"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 68"
                :key="option"
                :label="17 + option"
                :value="17 + option"
              ></el-option>
            </el-select>
          </div>

          <div class="year-type-wrapper" v-if="data.fields.type === 'age60'">
            <el-select
              v-model="fieldValue"
              :placeholder="data.fields.placeholder || '--'"
              class="date-year"
              key="date-year"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 50"
                :key="option"
                :label="59 + option"
                :value="59 + option"
              ></el-option>
            </el-select>
          </div>

          <div class="" v-if="data.fields.type === 'ajaxOpen'">
            <v-select
              :options="data.fields.options"
              :value="tempFieldValue"
              @input="updateFieldValue($event)"
              :close-on-select="true"
              v-on:search:blur="blurring()"
              v-on:search:focus="focusing()"
              :placeholder="data.fields.placeholder || 'Escriba aquí'"
            ></v-select>
          </div>

          <div v-if="data.fields.type === 'date'" class="date-wrapper">
            <el-select
              v-model="dateFieldYear"
              placeholder="Año"
              class="date-year"
              key="date-year"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 100"
                :key="option"
                :label="2020 - option"
                :value="2020 - option"
              ></el-option>
            </el-select>
            <el-select
              v-model="dateFieldMonth"
              placeholder="Mes"
              key="date-month"
              :popper-append-to-body="false"
              class="date-month"
            >
              <el-option
                v-for="(option, index) in monthNames"
                :key="option"
                :label="option"
                :value="index"
              ></el-option>
            </el-select>
            <el-select
              v-model="dateFieldDay"
              placeholder="Día"
              class="date-day"
              key="date-day"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in 31"
                :key="option"
                :label="option"
                :value="option"
              ></el-option>
            </el-select>
          </div>

          <div v-if="data.fields.type === 'select' && !data.fields.repeatField">
            <el-select
              @keyup.enter.native="next()"
              v-model="fieldValue"
              :placeholder="data.fields.placeholder || '--'"
              v-if="data.fields.type === 'select'" :key="data.fields.type"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in data.fields.options"
                :key="option"
                :value="option"
              >
              </el-option>
            </el-select>
            <div v-if="this.data.fields.altOption" class="alt-option">
              <div
                @click="redirectToSurvey()"
              >{{this.data.fields.altOption}}</div>
            </div>
          </div>

          <div v-if="data.fields.type === 'select' && data.fields.repeatField">
            <el-select
              class="pad-down"
              @keyup.enter.native="next()"
              v-for="i in data.fields.repeatField"
              v-model="fieldValueArray[i - 1]"
              :placeholder="`${toCardinal(i)} Prioridad`"
              v-if="data.fields.type === 'select'"
              :key="data.fields.type + '_' + i"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="option in randomizedOptions[i]"
                v-if="fieldValueArray.indexOf(option) === -1"
                :key="option"
                :value="option"
              >
              </el-option>
            </el-select>
          </div>

          <LocationSelectorLite
            :data="data"
            v-if="data.fields.type === 'location'"
            v-on:select="selectLocationAndGo($event)"
          ></LocationSelectorLite>

        </div>
        <LocationSelector2
          :data="data"
          v-if="data.fields.type !== 'location' && data.fields.locationSettings && data.fields.locationSettings.length"
        ></LocationSelector2>
      </div>

      <div class="field-wrapper" v-if="data.fields && data.fields.terms || data.fields.termsLink ">
        <el-checkbox v-model="form.terms" v-if="data.fields.terms || data.fields.termsLink" :key="'terms'" :required="true">
          {{data.fields.termsLinkAcknowledgeText || 'Acepto los términos y condiciones'}}
        </el-checkbox>
        <el-drawer
          :visible.sync="showTerms"
          size="90%"
          :append-to-body="true"
          v-if="data.fields.terms"
        >
          <vue-markdown class="html-content">{{data.fields.terms.fields.text}}</vue-markdown>          
        </el-drawer>
      </div>
      <div class="field-wrapper" v-if="data.fields && data.fields.terms" @click="showTerms = true">
        <p><a class="terms">{{data.fields.termsLinkLabel || '(ver términos y condiciones)'}}</a></p>
      </div>
      <div class="field-wrapper" v-if="data.fields && data.fields.termsLink">
        <p><a :href="data.fields.termsLink" class="terms" target="_self">
            {{data.fields.termsLinkLabel || '(ver términos y condiciones)'}}
          </a>
        </p>
      </div>

      <div class="fb-alert" v-if="fbAlert">
        <p>{{fbAlert}}</p>
      </div>

      <div class="fb-message" v-if="data.fields.facebookField">
        {{fbMessage}}
      </div>

    </content>
    <SkipQuestionButton
      :class="{'hidden': textInputOpen}"
      :disable-submit-button="disableSubmit"
      :show-submit="true"
      :is-mandatory="data.fields.mandatory"
      v-on:skip="skipQuestion()"
      v-on:submit="next()"
      :submit-text="form.terms ? data.fields.consentGivenText : data.fields.consentNotGivenText"
    ></SkipQuestionButton>

  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Vue from 'vue'

export default {
  name: 'TraditionalQuestion',

  props: [ 'data' ],

  components: {
    Icons: () => import('Components/base/Icons'),
    VueMarkdown,
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    LocationSelectorLite: () => import('Components/base/LocationSelectorLite'),
    LocationSelector2: () => import('Components/base/LocationSelector2')
  },

  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      exchangeRate: 1, // USD/new currency, defaults to USD/USD
      exchangeRateTable: {
        "Argentina": {
          short: "ARS",
          alias: "Pesos argentinos"
        },
        "Canadá": {
          short: "CAD",
          alias: "Dólares canadienses"
        },
        "Chile": {
          short: "CLP",
          alias: "Pesos chilenos"
        },
        "Colombia": {
          short: "COP",
          alias: "Pesos colombianos"
        },
        "República Dominicana": {
          short: "DOP",
          alias: "Pesos dominicanos"
        },
        "España": {
          short: "EUR",
          alias: "Euros"
        },
        "México": {
          short: "MXN",
          alias: "Pesos mexicanos"
        },
        "Panamá": {
          short: "USD",
          alias: "Dólares americanos"
        },
        "Perú": {
          short: "PEN",
          alias: "Soles"
        },
        "Paraguay": {
          short: "PYG",
          alias: "Guaraníes"
        },
        "Uruguay": {
          short: "UYU",
          alias: "Peso uruguayo"
        },
        "Ecuador": {
          short: "USD",
          alias: "Dólares americanos"
        },
        "Estados Unidos": {
          short: "USD",
          alias: "Dólares americanos"
        },
        "Venezuela": {
          short: "VEF",
          alias: "Bolívares"
        },
        "Estados Unidos": {
          short: "USD",
          alias: "Dólares americanos"
        },
        "Estados Unidos": {
          short: "USD",
          alias: "Dólares americanos"
        },
      },
      fieldValueArray: [],
      tempFieldValue: '',
      textInputOpen: false,
      valid: false,
      maskIsValid: false,
      showTerms: false,
      fieldValue: '',
      fbAlert: '',
      fbRerequest: '',
      dateFieldYear: null,
      dateFieldMonth: null,
      dateFieldDay: null,
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      form: {
        type: `traditionalQuestion`,
        identifier: this.data.fields.title,
        question: this.data.fields.content,
        numberOfOptions: this.data.fields.options ? this.data.fields.options.length : 0,
        id: this.data.sys.id,
        selected: [],
        terms: false,
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        },
        hubSpotField: this.data.fields.hubSpotField
      }
    }
  },

  computed: {
    randomizedOptions () {
      var array = {}
      if (this.data.fields.repeatField) {
        for (var i = 1; i <= this.data.fields.repeatField; i++) {
          array[i] = this.randomizeArray(JSON.parse(JSON.stringify(this.data.fields.options)))
        }
      }
      return array
    },
    qid () {
      return this.$route.params.qid
    },
    fbAuthResponse () {
      return this.$store.state.fbAuthResponse
    },
    fbData () {
      return this.$store.state.fbData
    },
    isMarkdown() {
      const markdownRegex = /[*_~#`]|!\[.*\]\(.*\)|\[[^\]]*\]\([^)]*\)/g;
      return markdownRegex.test(this.data.fields.content);
    },
    fbMessage () {
      // If it's a date
      if (this.data.fields.type === 'date') {
        if (this.dateFieldYear && this.dateFieldMonth + 1 && this.dateFieldDay) {
          var a = new Date(`${this.dateFieldMonth + 1}/${this.dateFieldDay}/${this.dateFieldYear}`)
          return `${a.getDate()} de ${this.monthNames[a.getMonth()]} de ${a.getFullYear()}. ¿Es correcto?`
        }
      }
      return ''
    },
    disableSubmit () {
      if (this.data.fields.type === 'consent' && this.form.terms) {
        return false
      }
      if (!this.fieldValue || this.fieldValue === '--' && this.data.fields.type !== 'consent') {
        return true
      }
      if (( this.data.fields.terms || this.data.fields.termsLink) && !this.form.terms) {
        return true
      }
      if (this.data.fields.inputmask && !this.maskIsValid) {
        return true
      }
      return false
    },
    defaultPlaceholderText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Enter here'
      }
      return 'Escribe aquí'
    }
  },

  mounted () {
    // auto skip if the value is stored in the device
    setTimeout(() => {
      if (this.data.fields.type === 'userId' && this.$store.state.trialUser.userId) {
        this.fieldValue = this.$store.state.trialUser.userId
        this.next()
      }
      if (this.data.fields.type === 'nickname' && this.$store.state.trialUser.nickname) {
        this.fieldValue = this.$store.state.trialUser.nickname
        this.next()
      }      
    }, 10)

    var fb = this.$store.state.fbData
    var field = this.data.fields.facebookField
    var value = fb[field]

    // If it's a birthday
    if (field && value) {
      switch (field) {
        case 'birthday':
          this.fieldValue = this.getFormattedDate(value)
        break
        default:
          this.fieldValue = value
        break
      }
    }
    setTimeout(() => {
      this.loadFB()
    }, 1000)

    if (this.data.fields.type === 'currency') {
      this.setCurrencyRate()
    }
  },

  methods: {
    redirectToSurvey () {
      if (!this.data.fields.altOptionRedirect) {
        this.fieldValue = this.data.fields.altOption
        this.next()      
        return
      }
      this.$store.dispatch('LOAD_NEW_SURVEY', { surveyId: this.data.fields.altOptionRedirect })
        .then(() => {
          // TODO: this does not work exactly as planned as the params are already in memory
          //this.$router.push(`/s/${newVal}/0?utm_campaign=live&utm_medium=referral&utm_source=${this.$route.params.sid}`)
          this.$router.push({
            name: 'survey',
            params: {
              sid: this.data.fields.altOptionRedirect,
              qid: 0
            }
          })
        })
    },
    randomizeArray (arr) {
      return arr.sort(item => {
        return 0.5 - Math.random()
      })
    },
    toCardinal (num) {
      if (num === 1) return 'Primera'
      if (num === 2) return 'Segunda'
      if (num === 3) return 'Tercera'
      if (num === 4) return 'Cuarta'
      if (num === 5) return 'Quinta'
    },
    // This is just a [WIP] —left in case this is useful
    setCurrencyRate () {
      if (this.$store.state.location && this.$store.state.location.country && this.$store.state.location.country.name) {
        return Vue.axios({
          method: 'get',
          url: `${process.env.VUE_APP_CURRENCY_EXCHANGE_ENDPOINT}`,
          headers: {
            'x-rapidapi-host': 'fixer-fixer-currency-v1.p.rapidapi.com',
            'x-rapidapi-key': process.env.VUE_APP_CURRENCY_EXCHANGE_API_KEY,
            useQueryString: true
          }
        })
          .then(data => {
            console.log('data: ', data)
          })
          .catch(err => {

            console.log('err: ', err)
          })        
      } else {

      }

    },
    updateFieldValue (e) {
      this.fieldValue = e
      this.tempFieldValue = e
    },
    blurring () {
      this.textInputOpen = false
    },
    focusing () {
      this.textInputOpen = true
    },
    maskCheck (field) {
      if (field.target.inputmask.isComplete()) {
        this.maskIsValid = true
      } else {
        this.maskIsValid = false
      }
    },
    selectLocationAndGo ($event) {
      this.fieldValue = $event;
      // Go to the next one if we are on the Country or State field selector
      if (this.data.fields.locationSettings && (this.data.fields.locationSettings.includes('Country') || this.data.fields.locationSettings.includes('State'))) {
        this.next()
      }
      if (this.data.fields.locationSettings.includes('GPS')) {
        this.next()
      }
    },
    getFormattedDate (value) {
      if (value && value.length === 10) {
        var a = new Date(value)
        var newDate = `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
        return newDate
      }
      if (value && value.length === 4) {
        var a = new Date(`01/01/${value}`)
        var newDate = `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
        return newDate
      }
      return ''
    },
    loadFB () {
      if (typeof window !== "undefined" && window.FB) {
        window.FB.init({
          xfbml: true,
          version: 'v3.0',
          appId  : process.env.VUE_APP_FACEBOOK_APP_ID,
        })
      }
    },
    loginFB () {
      if (this.data.fields.facebookField === 'birthday' && !this.fbData.birthday) {
        window.FB.login(response => {
          // TODO check all the other options
          if (response.status === 'connected') {
            this.$store.commit('STORE_FACEBOOK_RESPONSE', { response: response })
            this.accessToken = response.authResponse.accessToken
            window.FB.api('/me', {fields: 'name, email, birthday', accessToken: this.$store.state.fbAuthResponse.authResponse.accessToken}, newResponse => {
              this.$store.commit('STORE_FACEBOOK_DATA', { response: newResponse })
              if (newResponse.birthday) {
                this.fieldValue = this.getFormattedDate(newResponse.birthday)
                this.fbAlert = ''
                this.fbRerequest = false
              } else {
                if (response.authResponse.grantedScopes.indexOf('user_birthday') === -1) {
                  this.fbAlert = 'Si das permiso para compartir tu fecha de nacimiento, podrás ahorrar tiempo de diligenciamiento.'
                  this.fbRerequest = 'rerequest'
                } else {
                  this.fbAlert = 'Parece que no tienes configurada tu fecha de nacimiento o no está disponible.'
                }
              }
            })
          } 
        }, {scope: 'public_profile,email,user_birthday', return_scopes: true, auth_type: this.fbRerequest})
      }
    },
    skipQuestion () {
      this.fieldValue = 'S99'
      this.next()      
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      this.form.selected = [this.fieldValue]
      if (this.data.fields.type === 'userId') {
        this.$store.commit('STORE_TRIAL_USER_ID', { userId: this.form.selected[0] })
      }
      if (this.data.fields.type === 'nickname') {
        this.$store.commit('STORE_TRIAL_USER_NICKNAME', { nickname: this.form.selected[0] })
      }
      if (this.data.fields.storeResponseLocally) {
        this.$store.commit('STORE_LOCAL_RESPONSE', { key: this.form.identifier, value: this.form.selected[0] })
      }
      this.calculateScores()
    },
    calculateScores () {
      // This is a hack to allow skipping of opent text question if it's a number and it's mandatory.
      if (this.data.fields.type === 'number' && this.data.fields.mandatory === true) {
        this.locked = true
          this.form.skip = this.data.fields.skip
          if (this.data.fields.randomSkip) {
            var items = this.data.fields.randomSkip.split(',')
            this.form.skip = items[items.length * Math.random() | 0]
          }
          this.emitNext()
          return
      }

      // For all the other question types
      if (this.data.fields.type !== 'select' && this.data.fields.type !== 'location') {
        this.emitNext()
        return
      }
      this.locked = true
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: this.data.fields.options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.feedback = scores.feedback
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    },
    formatDate (date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  watch: {
    'showTerms': function (newValue) {
      if (newValue) {
        this.$emit('fullscreen:off')
      }
    },
    'fbMessage': function (newValue) {
      if (this.dateFieldYear && this.dateFieldDay) {
        var a = new Date(`${this.dateFieldMonth + 1}/${this.dateFieldDay}/${this.dateFieldYear}`)
        this.fieldValue = this.formatDate(a)
      }
    },
    'fieldValueArray': function (newValue) {
      if (this.data.fields.repeatField === this.fieldValueArray.length) {
        this.fieldValue = this.fieldValueArray.join('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.alt-option {
  margin-top: 2em;
  font-size: 16px;
  a {
    color: rgba($text-main, 0.6);
    text-decoration: none;
    border-bottom: 1px solid $gray-light;
  }
}
.pad-down {
  margin-bottom: 10px;
}
.hidden {
  opacity: 0.0001;
}

.year-type-wrapper {
  max-width: 100%;
}
.location-selector-wrapper {
  margin-bottom: 2em;
  font-size: 0.9em;
  color: $gray-medium;
}
.standard-select {
  width: 100%;
  height: 40px;
  font-size: 20px;
  font-family: 'Avenir', 'Nunito', sans-serif;
  color: $text-main;
  background: white;
}
.date-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
}

.fb-alert {
  width: 90%;
  margin: auto;
  font-size: 16px;  
}
.fb-message {
  width: 90%;
  margin: auto;
  min-height: 50px;
  font-size: 16px;  
}
.grid-wrapper {
  align-items: center;
  min-height: 20vh;
  margin: auto;
  width: 100%;
}

.field-wrapper {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.5em;

  @include breakpoint($tablet) {
    width: 100%;
    margin-bottom: 1.5em;
  }
  @include breakpoint($desktop) {
    transform: scale(1);
    margin-bottom: 0.5em;
  }
}
footer {
  margin-bottom: 20px;
  @include breakpoint($tablet) {
    margin-bottom: 80px;
  }
}

.content-wrapper {
  width: 94%;
  margin: auto;
}

.html-content {
  padding-left: 5%;
  padding-right: 5%;
  h1 {
    font-size: 1.8em;
  }
}
.terms {
  font-family: 'Avenir', 'Nunito', Helvetica, Arial, sans-serif;
  font-size: 16px;
  display: block;
}

</style>

<style lang="scss">
.vs__dropdown-menu {
  max-height: 30vh;
}

.v-select {
  .vs__dropdown-toggle {
    border-color: rgba($gray-medium, 0.5);
  }
  .vs__dropdown-option {
    font-family: 'Avenir', 'Nunito', sans-serif;
    font-weight: 300;
  }
  input {
    font-family: 'Avenir', 'Nunito', sans-serif;
    line-height: 35px;
    font-size: 20px;
  }
  .vs__search,
  .vs__selected {
    line-height: 35px;
    font-size: 20px;
  }
  .vs__dropdown-option--highlight {
    background-color: $color-emphasis;
  }
}

.year-type-wrapper {
  input.el-input__inner {
    text-align: center !important;
  }
}
.el-select {
  position: relative !important;
}
.el-select-dropdown {
  position: absolute !important;
  width: 100% !important;
  margin-top: 60px;
  min-width: 150px !important;
  max-width: 400px;
}

.date-wrapper {
  .date-year {
    .el-select-dropdown {
      width: 150px !important;
    }
  }
  .date-month {
    .el-select-dropdown {
      width: 200px !important;
      transform: translateX(-40px);
    }
    .popper__arrow {
      transform: translateX(40px);
    }
  }
  .date-day {
    .el-select-dropdown {
      width: 120px !important;
      transform: translateX(-50px);
    }
    .popper__arrow {
      transform: translateX(50px);
    }
  }
}


.el-select-dropdown__item {
  font-size: 17px !important;
  &.selected {
    color: $color-emphasis;
  }
}
.el-select {
  width: 90vw;
  @include breakpoint($tablet) {
    width: 100%;
  }
  .el-input.is-focus {
    .el-input__inner {
      border-color: $gray-medium !important;
    }
  }
  .el-input {
    .el-input__inner {
      &:focus {
        @include breakpoint($tablet) {
          border-color: $gray-medium !important;
        }
      }
    }
  }
}
.el-select-dropdown__wrap {
  max-height: 26vh !important;
}
.class-iOS {
  .el-select-dropdown__wrap {
    max-height: 220px !important;  
  }
}
.el-textarea {
  width: 90vw;  
}

.el-input__inner,
.el-textarea__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__input {
  transform: scale(1.4);
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: $color-emphasis !important;
  background-color: $color-emphasis !important;  
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $color-emphasis !important;
}
.el-checkbox__inner{
  &:hover {
    border-color: $color-emphasis !important;
  }
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $gray-medium !important;
}
.el-checkbox__label {
  font-size: 16px;
  line-height: normal;
}
.el-drawer__body {
  overflow-y: scroll;
  @include breakpoint($desktop) {
    font-size: 0.8em;
  }
}

.traditional-question {
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner   {
    width: 100% !important;
  }
  .field-wrapper {
    > div {
      flex: 1;
    }
  }
}

.traditional-question-outer-wrapper {
  .fb-login-cta {
    background: #3b5998;
    border-color: #3b5998;
    color: white;
    width: 100%;
    padding: 6px 10px;
    margin-bottom: 2em;
    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &:hover,
    &:focus {
      background: rgba(#3b5998, 0.9);
      border-color: rgba(#3b5998, 0.9);
      color: white;
    }
  }
}
.textfield-inputmask {
  width: 90%;
  margin: auto;
  border: 1px solid $color-emphasis;
  padding: 1em;
  color: $text-main !important;
  font-family: monospace;
  font-size: 20px !important;
  padding: 15px;
}
.textfield-ajaxOpen {
  width: 90%;
  margin: auto;
}
.html-content {
  h5 {
    font-size: 1.3em !important;
    font-weight: normal;
    margin-bottom: 0;
  }
}
</style>
